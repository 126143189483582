<template>
  <div class="last_update">
    <!-- <p>
      Última atualização: <b>Local: </b>
      <span>{{ lastUpdateLocal | filterDataHora }}</span> - <b>Servidor:</b
      ><span>{{ lastUpdate | filterDataHora }}</span>
    </p> -->
  </div>
</template>

<script>
import { filterDataHora } from "@/utils/date";

export default {
  props: [],
  data() {
    return {
      counterInterval: null,
      lastUpdateLocal: new Date(),
    };
  },
  filters: {
    filterDataHora,
  },
  methods: {
    loadData() {
      this.lastUpdateLocal = new Date();
    },
  },
  computed: {
    lastUpdate() {
      return this.$store.state.dashboard.lastUpdate;
    },
  },
};
</script>

<style lang="scss" scoped>
b {
  font-weight: bold;
}
@media only screen and (max-width: 991px) {
  .last_update {
    display: none;
  }
}
</style>
